import revive_payload_client_4sVQNw7RlN from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_Eeyr9ytLpT from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import payload_client_yVLowv6hDl from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_mik7N71GmK from "/opt/build/repo/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_7e4f6da8_cLvyS16cBv from "/opt/build/repo/.nuxt/templates.plugin.7e4f6da8.ejs";
import plugin_client_mLgZJrmjnn from "/opt/build/repo/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/opt/build/repo/.nuxt/unocss.mjs";
import plugin_client_i8AMfKeYnY from "/opt/build/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_OrkQhMqHci from "/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import defaults_XmTMBbvpdp from "/opt/build/repo/node_modules/@nuxtseo/module/dist/runtime/plugin/defaults.mjs";
import siteConfig_AMQJFXRQGE from "/opt/build/repo/node_modules/nuxt-seo-experiments/dist/runtime/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_b6ZmJVCPag from "/opt/build/repo/node_modules/nuxt-seo-experiments/dist/runtime/plugins/inferSeoMetaPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import ssr_plugin_B4ptqVdIfe from "/opt/build/repo/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import gtag_client_GFj0J3dpn8 from "/opt/build/repo/src/plugins/gtag.client.js";
import scrollToTop_qK8NwO4Kxq from "/opt/build/repo/src/plugins/scrollToTop.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  siteConfig_Eeyr9ytLpT,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_mik7N71GmK,
  templates_plugin_7e4f6da8_cLvyS16cBv,
  plugin_client_mLgZJrmjnn,
  unocss_MzCDxu9LMj,
  plugin_client_i8AMfKeYnY,
  plugin_OrkQhMqHci,
  defaults_XmTMBbvpdp,
  siteConfig_AMQJFXRQGE,
  inferSeoMetaPlugin_b6ZmJVCPag,
  chunk_reload_client_UciE0i6zes,
  ssr_plugin_B4ptqVdIfe,
  gtag_client_GFj0J3dpn8,
  scrollToTop_qK8NwO4Kxq
]