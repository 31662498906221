import { default as _91bookId_93jNLkrzZzUyMeta } from "/opt/build/repo/src/pages/[shelfId]/[bookId].vue?macro=true";
import { default as aboutm5RZo0AsoGMeta } from "/opt/build/repo/src/pages/about.vue?macro=true";
import { default as adminG91UhNIpBvMeta } from "/opt/build/repo/src/pages/admin.vue?macro=true";
import { default as indexMUmX8mAfjNMeta } from "/opt/build/repo/src/pages/index.vue?macro=true";
import { default as loginwV8UxyWAwuMeta } from "/opt/build/repo/src/pages/login.vue?macro=true";
import { default as searchl7Qgt57VXrMeta } from "/opt/build/repo/src/pages/search.vue?macro=true";
import { default as _91id_93ZYfdg2cYtgMeta } from "/opt/build/repo/src/pages/submitted/[id].vue?macro=true";
import { default as _91_46_46_46collection_93N6r5jCajR8Meta } from "/opt/build/repo/src/pages/suggest/[...collection].vue?macro=true";
export default [
  {
    name: _91bookId_93jNLkrzZzUyMeta?.name ?? "shelfId-bookId",
    path: _91bookId_93jNLkrzZzUyMeta?.path ?? "/:shelfId()/:bookId()",
    meta: _91bookId_93jNLkrzZzUyMeta || {},
    alias: _91bookId_93jNLkrzZzUyMeta?.alias || [],
    redirect: _91bookId_93jNLkrzZzUyMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/[shelfId]/[bookId].vue").then(m => m.default || m)
  },
  {
    name: aboutm5RZo0AsoGMeta?.name ?? "about",
    path: aboutm5RZo0AsoGMeta?.path ?? "/about",
    meta: aboutm5RZo0AsoGMeta || {},
    alias: aboutm5RZo0AsoGMeta?.alias || [],
    redirect: aboutm5RZo0AsoGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: adminG91UhNIpBvMeta?.name ?? "admin",
    path: adminG91UhNIpBvMeta?.path ?? "/admin",
    meta: adminG91UhNIpBvMeta || {},
    alias: adminG91UhNIpBvMeta?.alias || [],
    redirect: adminG91UhNIpBvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: indexMUmX8mAfjNMeta?.name ?? "index",
    path: indexMUmX8mAfjNMeta?.path ?? "/",
    meta: indexMUmX8mAfjNMeta || {},
    alias: indexMUmX8mAfjNMeta?.alias || [],
    redirect: indexMUmX8mAfjNMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginwV8UxyWAwuMeta?.name ?? "login",
    path: loginwV8UxyWAwuMeta?.path ?? "/login",
    meta: loginwV8UxyWAwuMeta || {},
    alias: loginwV8UxyWAwuMeta?.alias || [],
    redirect: loginwV8UxyWAwuMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: searchl7Qgt57VXrMeta?.name ?? "search",
    path: searchl7Qgt57VXrMeta?.path ?? "/search",
    meta: searchl7Qgt57VXrMeta || {},
    alias: searchl7Qgt57VXrMeta?.alias || [],
    redirect: searchl7Qgt57VXrMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZYfdg2cYtgMeta?.name ?? "submitted-id",
    path: _91id_93ZYfdg2cYtgMeta?.path ?? "/submitted/:id()",
    meta: _91id_93ZYfdg2cYtgMeta || {},
    alias: _91id_93ZYfdg2cYtgMeta?.alias || [],
    redirect: _91id_93ZYfdg2cYtgMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/submitted/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46collection_93N6r5jCajR8Meta?.name ?? "suggest-collection",
    path: _91_46_46_46collection_93N6r5jCajR8Meta?.path ?? "/suggest/:collection(.*)*",
    meta: _91_46_46_46collection_93N6r5jCajR8Meta || {},
    alias: _91_46_46_46collection_93N6r5jCajR8Meta?.alias || [],
    redirect: _91_46_46_46collection_93N6r5jCajR8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/src/pages/suggest/[...collection].vue").then(m => m.default || m)
  }
]