
import { updateAppConfig } from '#app'
import { defuFn } from '/opt/build/repo/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "firebaseConfig": {
    "apiKey": "AIzaSyBUYpAbKFiCuX1pAc6nL6YzW4FiZVVBIUE",
    "authDomain": "humanities-book.firebaseapp.com",
    "projectId": "humanities-book",
    "storageBucket": "humanities-book.appspot.com",
    "messagingSenderId": "1099251561119",
    "appId": "1:1099251561119:web:0a95dc44c8d2937bc4e8aa",
    "measurementId": "G-SRNK2YB4GK"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "auth": true,
    "config": {
      "apiKey": "AIzaSyBUYpAbKFiCuX1pAc6nL6YzW4FiZVVBIUE",
      "authDomain": "humanities-book.firebaseapp.com",
      "projectId": "humanities-book",
      "storageBucket": "humanities-book.appspot.com",
      "messagingSenderId": "1099251561119",
      "appId": "1:1099251561119:web:0a95dc44c8d2937bc4e8aa",
      "measurementId": "G-SRNK2YB4GK"
    }
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/build/repo/node_modules/nuxt-seo-ui/dist/runtime/app.config.mjs"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
